import { UI } from "../../constants/action-constants";
import URIs from "../../constants/endpoints";
import globalService from "../../services";

export const setThemeLocale = (value) => {
  const languages = [
    { acronym: "PHUK", lang: "EN" },
    { acronym: "PHGR", lang: "DE" },
    { acronym: "PHFR", lang: "FR" },
    { acronym: "PHSP", lang: "ES" },
  ];
  localStorage.setItem(
    "selectedLang",
    JSON.stringify(languages.find((obj) => obj.lang === value))
  );
  return { type: UI.SET_LOCALE_THEME, payload: value };
};
export const fetchGeoIPLocation = () => async (dispatch) => {
  try {
    let response;
    response = await globalService().fetchGeoIPLocation();
    if (response.data && response?.status === 200 && response.data.country_code) {
      ["DE","ES","FR"].includes(response.data.country_code) &&
        dispatch({
        type: UI.SET_LOCALE_THEME, payload: response.data.country_code
        });
    }
  } catch (e) {
  }
};

export const snackbarActions = {
  showSnackbar: (snackbarMessage, snackbarVariant) => {
    return {
      type: UI.SHOW_SNACKBAR,
      data: { snackbarMessage, snackbarVariant },
    };
  },
  hideSnackbar: () => {
    return { type: UI.HIDE_SNACKBAR };
  },
};

export const setChartFilter = (filter) => {
  return { type: UI.SET_CHART_FILTER, payload: filter };
};

export const setDeviceFilter = (device) => {
  return { type: UI.SET_DEVICE_FILTER, payload: device };
};

export const loaderActions = {
  showLoader: () => {
    return { type: UI.SHOW_LOADER };
  },
  hideLoader: () => {
    return { type: UI.HIDE_LOADER };
  },
};

export const fetchReferenceData = (type) => async (dispatch) => {
  try {
    let response;
    const refData = "REFERENCE_DATA_" + type.toUpperCase();
    let refURL = URIs[refData];
    response = await globalService().fetchReferenceData({ url: refURL });
    if (response.data) {
      dispatch({
        type: UI.FETCH_REFERENCE_DATA,
        payloadType: type,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch(
      snackbarActions.showSnackbar(
        e.message || "Something went wrong..!",
        "error"
      )
    );
  }
};
